import React from 'react';

const SectionPart = ({
  icon,
  heading,
  description,
}: {
  icon: any;
  heading: string;
  description: string;
}) => (
  <div>
    <div className="mt-8x lg:mt-0 flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
      {icon}
    </div>
    <div className="mt-5">
      <h5 className="text-lg leading-6 font-medium text-gray-900">{heading}</h5>
      <p className="mt-2 text-base leading-6 text-gray-500">{description}</p>
    </div>
  </div>
);

const Section = () => (
  <div className="py-12 bg-white">
    <div className="max-w-xl mx-auto  lg:max-w-screen-xl">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <SectionPart
            heading="Seamless transactions"
            description="With a fully cashless, in app payment option allow for the smoothest serving experience. No more having to handle cash or card machines!"
            icon={
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                />
              </svg>
            }
          />
          <SectionPart
            heading="Safe service"
            description="Allow safe service minimising the time your customers have to be at the till, while improving the cusomers experience."
            icon={
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            }
          />
          <SectionPart
            heading="No hidden fees"
            description="Our fully transparent, simple pricing model means that you'll be taking more money home."
            icon={
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
            }
          />
        </div>
      </div>
    </div>
  </div>
);

export default Section;
