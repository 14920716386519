import React, { useState } from 'react';
import { client } from '../client';
import Button from '../Button';
import Alert from '../Alert';

const submitForm = async (
  name: string,
  email: string,
  phone: string,
  message: string,
) => {
  const payload = {
    operationName: null,
    variables: {
      contactMessage: {
        phoneNumber: phone,
        email: email,
        fullName: name,
        message: message,
      },
    },
    query:
      'mutation ($contactMessage: ContactMessageInput!) {\n  createContactMessage(message: $contactMessage)\n}\n',
  };
  try {
    const resp = await client({ body: payload });
    console.log(resp);

    if (!resp.data.createContactMessage) {
      throw new Error();
    }
  } catch (e) {
    console.error(e);
    throw new Error(
      'Could not send message please try again or try emailing us as queries@quickk.co.uk',
    );
  }
};

const ContactUs = () => {
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [request, setRequest] = useState<
    | { state: 'IDLE' }
    | { state: 'LOADING' }
    | { state: 'SUCCESS' }
    | { state: 'ERROR'; message: string }
  >({ state: 'IDLE' });
  console.log(request);
  return (
    <div className="relative bg-white">
      <div className="max-w-xl mx-auto  lg:max-w-screen-xl">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                We'd love to help you serve your customers better. Please get in
                touch with us if you have any questions.
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-500">
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <span className="ml-3">07778536612</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">queries@quickk.co.uk</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setRequest({ state: 'LOADING' });

                  try {
                    await submitForm(fullName, email, phone, message);
                    setEmail('');
                    setFullName('');
                    setPhone('');
                    setMessage('');
                    setRequest({ state: 'SUCCESS' });
                  } catch (e) {
                    console.error(e);
                    setRequest({ state: 'ERROR', message: e.message });
                  }
                }}
                className="grid grid-cols-1 row-gap-6"
              >
                <div>
                  <label htmlFor="full_name" className="sr-only">
                    Full name
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      disabled={request.state === 'LOADING'}
                      value={fullName}
                      onChange={(e) => {
                        e.preventDefault();
                        setFullName(e.target.value);
                      }}
                      id="full_name"
                      className="rounded-md border border-gray-200 block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      disabled={request.state === 'LOADING'}
                      value={email}
                      onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                      }}
                      id="email"
                      type="email"
                      className="rounded-md border border-gray-200 block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      disabled={request.state === 'LOADING'}
                      value={phone}
                      onChange={(e) => {
                        e.preventDefault();
                        setPhone(e.target.value);
                      }}
                      id="phone"
                      className="rounded-md border border-gray-200 block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <textarea
                      disabled={request.state === 'LOADING'}
                      value={message}
                      onChange={(e) => {
                        e.preventDefault();
                        setMessage(e.target.value);
                      }}
                      id="message"
                      rows={4}
                      className="rounded-md border border-gray-200 block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="">
                  {request.state === 'ERROR' && (
                    <>
                      <Alert tone="critical">{request.message}</Alert>
                      <div style={{ marginBottom: 24 }}></div>
                    </>
                  )}
                  {request.state === 'SUCCESS' && (
                    <>
                      <Alert tone="positive">
                        Thank you for messaging us, we will get back to you as
                        quickk as we can
                      </Alert>
                      <div style={{ marginBottom: 24 }}></div>
                    </>
                  )}
                  <span className="inline-flex rounded-md shadow-sm">
                    <Button
                      disabled={
                        !fullName ||
                        !email ||
                        !message ||
                        !phone ||
                        request.state === 'LOADING'
                      }
                      type="submit"
                      weight="strong"
                    >
                      Submit
                    </Button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
