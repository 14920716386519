import React from 'react';
import Hero from './Hero';
import Section from './Section';
import Footer from './Footer';
import ContactUs from './ContactUs';
import WhoAreWe from './WhoAreWe';
import Pricing from './Pricing';

const Home = () => {
  return (
    <>
      <Hero />
      <Section />
      <WhoAreWe />
      <Pricing />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
