import React, { MouseEvent, ReactChild } from 'react';

const Button = (props: {
  children: ReactChild;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type: 'button' | 'submit' | 'reset';
  weight: 'regular' | 'strong' | 'weak';
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  let className =
    'inline-flex items-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150';
  if (props.weight === 'strong') {
    className = `inline-flex items-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white ${
      props.disabled ? 'bg-gray-500' : 'bg-indigo-600'
    }  ${props.disabled ? 'bg-gray-500' : 'hover:bg-indigo-500'}
     focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`;
  }
  if (props.weight === 'weak') {
    className =
      'inline-flex items-center py-3 px-6 border border-gray-300 text-base leading-6 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150';
  }
  if (props.fullWidth) {
    className = `${className} w-full justify-center`;
  }
  if (props.className) {
    className = `${className} ${props.className}`;
  }

  if (props.disabled) {
    className = `${className} cursor-default`;
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type || 'button'}
      className={className}
    >
      {props.children}
    </button>
  );
};

export default Button;
