import React from 'react';

const Footer = () => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="mt-8">
        <p className="text-center text-base leading-6 text-gray-400">
          &copy; 2020 Quikk All rights reserved.
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
