const API_URL = 'https://api.quickk.co.uk/graphql';

export const client = async ({ body, ...customConfig }: any = {}) => {
  const headers: any = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    if (config.headers['Content-Type'] !== 'application/json') {
      config.body = body;
    } else {
      config.body = JSON.stringify(body);
    }
  }

  const resp = await fetch(`${API_URL}`, config);

  const data = await resp.json();

  if (!resp.ok) {
    throw data;
  }

  return data;
};
